<template>
  <v-navigation-drawer permanent ref="drawer">
    <v-sheet
        color="grey lighten-4"
        class="pt-4"
    >
      <v-row justify="center">
        <v-date-picker dense no-title :show-adjacent-months="false" full-width
                       :value="selectedDate" @input="changeSelectedDate($event)"></v-date-picker>
      </v-row>
      <v-row justify="center">
      </v-row>
    </v-sheet>
    <v-list
        subheader
        dense
    >
      <v-subheader>Calendars</v-subheader>
      <v-list-item-group>
        <v-list-item v-for="group in scopedGroups"
                     :key="group._id">
          <v-list-item-action>
            <v-checkbox
                v-model="localSelectedCalendars"
                :value="group._id" @change="updateSelectedCalendars($event)"/>
          </v-list-item-action>
          <v-list-item-content>
            {{ group.name }}
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-divider/>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "CalendarNavigationDrawer",
  props:{
    scopedGroups:{
      type:Array,
      required: true
    },
    selectedDate:{
      required: true
    },
    selectedCalendars:{
      type:Array,
      required: true
    }
  },
  methods:{
    changeSelectedDate(event){
      this.$emit("updateSelectedDate",event)
    },
    updateSelectedCalendars(event){
      this.$emit("updateSelectedCalendars",event)
    },
  },
  mounted() {
    const defaultCalendars = this.scopedGroups.map(calendar => calendar._id)
    this.$emit("updateSelectedCalendars",defaultCalendars)
  },
  data () {
    return {
      localSelectedCalendars: this.selectedCalendars // Initial value
    }
  },
  watch: {
    selectedCalendars (newValue) {
      this.localSelectedCalendars = newValue // Update when the prop changes
    }
  }
}
</script>

<style scoped>

</style>