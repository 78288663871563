import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{ref:"drawer",attrs:{"permanent":""}},[_c(VSheet,{staticClass:"pt-4",attrs:{"color":"grey lighten-4"}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VDatePicker,{attrs:{"dense":"","no-title":"","show-adjacent-months":false,"full-width":"","value":_vm.selectedDate},on:{"input":function($event){return _vm.changeSelectedDate($event)}}})],1),_c(VRow,{attrs:{"justify":"center"}})],1),_c(VList,{attrs:{"subheader":"","dense":""}},[_c(VSubheader,[_vm._v("Calendars")]),_c(VListItemGroup,_vm._l((_vm.scopedGroups),function(group){return _c(VListItem,{key:group._id},[_c(VListItemAction,[_c(VCheckbox,{attrs:{"value":group._id},on:{"change":function($event){return _vm.updateSelectedCalendars($event)}},model:{value:(_vm.localSelectedCalendars),callback:function ($$v) {_vm.localSelectedCalendars=$$v},expression:"localSelectedCalendars"}})],1),_c(VListItemContent,[_vm._v(" "+_vm._s(group.name)+" ")])],1)}),1),_c(VDivider)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }