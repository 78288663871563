<template>
    <v-navigation-drawer
        right
        v-model="manageCalendarFilterDialog"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>

          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Filters</v-list-item-title>
            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn fab @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>

      <v-divider></v-divider>
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Day View Hours Filter
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-row>
                  <v-col cols="12"></v-col>
                </v-row>
              </v-row>
              <v-row>
                <v-col cols="6">Start Time</v-col>
                <v-col cols="6"><TimePickerSelector :value="localCalendarDayStartTimeMinutes" v-on:input="changeCalendarDayStartTimeMinutes($event)" :max-time-minutes="calendarDayEndTimeMinutes"/></v-col>
              </v-row>
              <v-row>
                <v-col cols="6">End Time</v-col>
                <v-col cols="6"><TimePickerSelector :value="localCalendarDayEndTimeMinutes" v-on:input="changeCalendarDayEndTimeMinutes($event)" :min-time-minutes="calendarDayStartTimeMinutes" /></v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
<!--        <v-expansion-panel>-->
<!--          <v-expansion-panel-header>-->
<!--            Calendar Options-->
<!--          </v-expansion-panel-header>-->
<!--          <v-expansion-panel-content>-->
<!--            interval-minutes="30"-->
<!--          </v-expansion-panel-content>-->
<!--        </v-expansion-panel>-->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Categories
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list-item-group>
              <v-list-item v-for="(category,i) in allSelectableEventCategories"
                           :key="i">
                <v-list-item-action>
                  <v-checkbox
                      v-model="localSelectedCategories"
                      :value="category.name" @change="updateSelectedCategories($event)"/>
                </v-list-item-action>
                <v-list-item-content>
                  {{ category.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-expansion-panel-content>

        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Tags
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list-item-group>
              <v-list-item v-for="tag in allSelectableTags"
                           :key="tag.name">
                <v-list-item-action>
                  <v-checkbox
                      v-model="localSelectedTags"
                      :value="tag.name" @change="updateSelectedTags($event)"/>
                </v-list-item-action>
                <v-list-item-content>
                  {{ tag.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-expansion-panel-content>

        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Calendar Views
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list-item-group v-model="localWeekFormatting">
              <template v-for="(item, i) in weekdays">
                <v-divider
                    v-if="!item"
                    :key="`divider-${i}`"
                ></v-divider>

                <v-list-item
                    v-else
                    :key="`item-${i}`"
                    :value="item.value"
                    @change="updateSelectedCalendarView(item.value)"
                    active-class="secondary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                          :input-value="active"
                          color="primary accent-4"
                          disabled
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Event Overlap mode
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list-item-group v-model="localEventOverLapMode">
              <template v-for="(item, i) in modes">
                <v-divider
                    v-if="!item"
                    :key="`divider-${i}`"
                ></v-divider>

                <v-list-item
                    v-else
                    :key="`item-${i}`"
                    :value="item"
                    @change="updateEventOverlapMode(item)"
                    active-class="secondary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                          :input-value="active"
                          color="primary accent-4"
                          disabled
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from "vuex";
import TimePickerSelector from "@/components/shared/TimePickerSelector";

export default {
  name: "CalendarFilterNavigationDrawer",
  components: {TimePickerSelector},
  props:{
    allSelectableEventCategories:{
      type:Array,
      required: true
    },
    selectedCategories:{
      type:Array,
      required: true
    },
    allSelectableTags:{
      type:Array,
      required: true
    },
    selectedTags:{
      type:Array,
      required: true
    },
    weekday:{
      required: true,
      type: Array,
      default: () => [1, 2, 3, 4, 5, 6, 0]
    },
    eventOverLapMode:{
      required: true,
      type: String,
      default:"stack"
    },
    calendarDayStartTimeMinutes: {
      required: true,
    },
    calendarDayEndTimeMinutes: {
      required: true,
    },
    calendarDayIntervalMinutes:{
      required: true,
      type:Number,
      default:30
    },
  },
  computed: {
    ...mapState("calendar", ["manageCalendarFilterDialog"]),
  },
  methods:{
    ...mapActions("calendar", ["setManageCalendarFilterDialog"]),

    updateSelectedCategories(event){
      this.$emit("updateSelectedCategories",event)
    },
    updateSelectedTags(event){
      this.$emit("updateSelectedTags",event)
    },

    updateSelectedCalendarView(event){
      this.$emit("updateSelectedCalendarView",event)
    },

    updateEventOverlapMode(event){
      this.$emit("updateEventOverlapMode",event)
    },

    changeCalendarDayStartTimeMinutes(event){
      this.$emit("changeCalendarDayStartTimeMinutes",event)
    },

    changeCalendarDayEndTimeMinutes(event){
      this.$emit("changeCalendarDayEndTimeMinutes",event)
    },

    closeDialog() {
      this.setManageCalendarFilterDialog(false)
    }
  },
  data() {
    return {
    weekdays: [
      {text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6]},
      {text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0]},
      {text: 'Weekends', value: [6, 0]},
      {text: 'Weekdays', value: [1, 2, 3, 4, 5]},
      {text: 'Thurs-Sun', value: [4, 5, 6, 0]},
    ],
      modes: ['stack', 'column'],
      localCalendarDayStartTimeMinutes:this.calendarDayStartTimeMinutes,
      localCalendarDayEndTimeMinutes:this.calendarDayEndTimeMinutes,
      localSelectedCategories:this.selectedCategories,
      localSelectedTags:this.selectedTags,
      localWeekFormatting:this.weekdays,
      localEventOverLapMode:this.eventOverLapMode
    }
  },
  watch: {
    calendarDayStartTimeMinutes (newValue) {
      this.localCalendarDayStartTimeMinutes = newValue // Update when the prop changes
    },
    selectedCategories (newValue) {
      this.localSelectedCategories = newValue // Update when the prop changes
    },
    selectedTags (newValue) {
      this.localSelectedTags = newValue // Update when the prop changes
    },
    weekdays (newValue) {
    this.localWeekFormatting =newValue
    },
    eventOverLapMode (newValue) {
      this.localEventOverLapMode =newValue
    }
  }
}
</script>

<style scoped>

</style>