<template>
  <v-container>
    <calendar-event-modal :scoped-groups="scopedGroups" v-on:event-changed="refresh"
                          :calendar-range="{start:'2022-03-01',end:'2022-03-31'}"/>
    <v-row>
      <v-col>
        <calendar-navigation-drawer :scoped-groups="scopedGroups"
                                    :selected-date="selectedDate"
                                    :selected-calendars="selectedCalendars"
                                    v-on:updateSelectedDate="updateSelectedDate($event)"
        />
      </v-col>
      <v-col cols="8" xs="12">
        <v-sheet>
          <v-row>
            <v-spacer></v-spacer>
            <v-col>
              <app-btn color="primary" @click="newEvent"><v-icon>mdi-calendar</v-icon>New</app-btn>
            </v-col>
            <v-col>
              <app-btn @click="toggleFilters" small >
                <span class="hidden-sm-and-down">Filter</span>
                <v-icon right>
                  mdi-calendar-filter
                </v-icon>
              </app-btn>
            </v-col>
          </v-row>
        </v-sheet>
        <!--        <v-sheet height="600">-->
        <calendar :items="filteredEvents"
                  :mode="eventOverLapMode"
                  :weekday="weekday"
                  :selected-date="selectedDate"
                  :scoped-groups="scopedGroups"
                  :categories="selectedCalendars"
                  :calendar-day-start-time-minutes="calendarDayStartTimeMinutes"
                  :calendar-day-end-time-minutes="calendarDayEndTimeMinutes"
                  :calendar-day-interval-minutes="calendarDayIntervalMinutes"
                  v-on:updateSelectedDate="selectedDate=$event"
                  v-on:changeCalendarViewStartAndEndValues="changeCalendarViewStartAndEndValues($event)"
                  v-on:toggleFilters="toggleFilters"
                  v-on:refreshCalendar="refresh"
        />
        <!--          <div>Selected Calendars = {{ selectedCalendars }}</div>-->
        <!--          <div>Selected Categories = {{ selectedCategories }}</div>-->
        <!--          <div>Selected Tags = {{ selectedTags }}</div>-->
        <!--        </v-sheet>-->
      </v-col>
      <v-col>
        <calendar-filter-navigation-drawer
            :weekday="weekday"
            :event-over-lap-mode="eventOverLapMode"
            :all-selectable-event-categories="allSelectableEventCategories"
            :selected-categories="selectedCategories"
            :selected-tags="selectedTags"
            :all-selectable-tags="allSelectableTags"
            :calendar-day-start-time-minutes="calendarDayStartTimeMinutes"
            :calendar-day-end-time-minutes="calendarDayEndTimeMinutes"
            :calendar-day-interval-minutes="calendarDayIntervalMinutes"
            v-on:updateSelectedCalendars="changeSelectedCalendars($event)"
            v-on:updateSelectedCategories="changeSelectedCategories($event)"
            v-on:updateSelectedTags="changeSelectedTags($event)"
            v-on:updateSelectedCalendarView="updateSelectedCalendarView($event)"
            v-on:updateEventOverlapMode="updateEventOverlapMode($event)"
            v-on:changeCalendarDayStartTimeMinutes="changeCalendarDayStartTimeMinutes"
            v-on:changeCalendarDayEndTimeMinutes="changeCalendarDayEndTimeMinutes"
        />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import CalendarNavigationDrawer from "@/components/calendar/CalendarNavigationDrawer";
import Calendar from "@/components/calendar/Calendar";
import CalendarFilterNavigationDrawer from "@/components/calendar/CalendarFilterNavigationDrawer";
import AppBtn from "@/components/app/Btn";
import CalendarEventModal from "@/components/calendar/CalendarEventModal";

export default {
  name: "CalendarPage",
  components: {CalendarEventModal, AppBtn, CalendarFilterNavigationDrawer, Calendar, CalendarNavigationDrawer},
  computed: {
    ...mapState("app", ["loading", "loggedInUser"]),
    ...mapState("group", ["groups"]),
    ...mapState("calendar", ["showAsList", "events"]),
    ...mapState("user", ["groupMembership"]),

    scopedGroups() {
      let userGroupIds = this.groupMembership.map(g => g._id)
      return this.groups.filter(g => userGroupIds.some(id => id === g._id))
    },

    defaultCalendarEventCategories() {
      return this.allSelectableEventCategories.filter(c => c.showInDefaultView).map(a => a.name);
    },

    allSelectableEventCategories() {
      let arr = []
      this.scopedGroups.forEach(g => {
        arr = [...arr, ...g.calendarEventCategories]
      })
      return arr
    },

    allSelectableTags() {
      let arr = []
      this.scopedGroups.forEach(g => {
        arr = [...arr, ...g.eventTags]
      })
      return arr
    },

  },
  data() {
    return {
      value: '',
      ready: false,
      drawer: true,
      selectedDate: '',
      calRange: {start: '', end: ''},
      colorHighlight: 1,
      colorHighlights: [{text: "Event Status", value: "status"}, {text: "Category", value: "category"}],
      selectedCalendar: null,
      selectedCalendars: [],
      selectedCategories: [],
      selectedTags: [],
      selectedResourcesTypes: [],
      filteredEventTags: [],
      filteredEvents: [],
      scopedEvents: [],
      renderCalendar: true,
      weekday: [1, 2, 3, 4, 5, 6, 0],
      eventOverLapMode: 'stack',
      calendarDayStartTimeMinutes: 420,
      calendarDayEndTimeMinutes: 1200,
      calendarDayIntervalMinutes: 30,
    }
  },
  mounted() {
    this.selectedCalendars = this.scopedGroups.map(calendar => calendar._id)
    this.selectedCategories = this.defaultCalendarEventCategories
  },
  methods: {
    ...mapActions("calendar", ["setManageCalendarEventDialog", "setActiveCalendarEvent", "setCalendars", "setManageCalendarFilterDialog"]),

    updateSelectedDate(event) {
      this.selectedDate = event
    },

    changeCalendarViewType(calendarViewTypeRequested) {
      this.type = calendarViewTypeRequested
      this.forceCalendarRerender()
    },

    changeSelectedCalendars(calendarIdArray) {
      this.selectedCalendars = calendarIdArray
      this.getEvents()
    },

    changeSelectedCategories(arg) {
      this.selectedCategories = arg
      this.getEvents()
    },
    changeSelectedTags(arg) {
      this.selectedTags = arg
      this.getEvents()
    },

    updateSelectedCalendarView(arg) {
      this.weekday = arg
    },
    updateEventOverlapMode(arg) {
      this.eventOverLapMode = arg
    },

    changeCalendarDayStartTimeMinutes(arg) {
      this.calendarDayStartTimeMinutes = arg
    },

    changeCalendarDayEndTimeMinutes(arg) {
      this.calendarDayEndTimeMinutes = arg
    },

    async forceCalendarRerender() {
      // Remove MyComponent from the DOM
      this.renderCalendar = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderCalendar = true;
    },

    async changeCalendarViewStartAndEndValues(calendarStartAndEndDates) {
      this.calRange = calendarStartAndEndDates
      await this.setCalendars(calendarStartAndEndDates)
      this.getEvents()
    },

    async refresh() {
      await this.setCalendars(this.calRange)
      this.getEvents()
    },

    async newEvent() {
      await this.setActiveCalendarEvent({status: "confirmed",timed:false, tags: [] })
      await this.setManageCalendarEventDialog(true)
    },

    getEvents() {
      const events = this.events
      events.forEach(e => {
        e.start = new Date(e.start)
        e.end = new Date(e.end)
      })
      this.scopedEvents = events
      this.filterEvents()
    },
    filterEvents() {
      // filter events by user's selection
      this.filteredEvents = this.scopedEvents.filter(e => this.selectedCalendars.includes(e.calendarId)
          && this.selectedCategories.includes(e.category) ||
          this.selectedCalendars.includes(e.calendarId)
          && this.selectedTags.some(v => e.tags.includes(v)))

    },

    toggleFilters() {
      this.setManageCalendarFilterDialog(true)
    },
  }
}
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>