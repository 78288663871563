<template>
  <v-select :value="contentValue"
            v-on:input="$emit('input', $event)" :items="scopedItems"></v-select>
</template>

<script>
export default {
  name: "TimePickerSelector",
  props: {
    value: {
      type: Number
    },
    minTimeMinutes: {
      type: Number,
      default: 0
    },
    maxTimeMinutes: {
      type: Number,
      default: 1440
    }
  },
  computed: {
    items() {
      let items = []
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 2; j++) {
          items.push({
            text: `${i < 10 ? `0${i}` : i}:${j === 0 ? `00` : 30 * j}`,
            value: j === 0 ? 60 * i : 60 * i * j
          });
        }
      }
      return items
    },
    scopedItems() {
      return this.items.filter(t => t.value >= this.minTimeMinutes).filter(t => t.value <= this.maxTimeMinutes)
    }
  },
  data () {
    return {
      contentValue: this.value // Initial value
    }
  },
  watch: {
    value (newValue) {
      this.contentValue = newValue // Update when the prop changes
    }
  }

}
</script>

<style scoped>

</style>