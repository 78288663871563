<template>
  <v-dialog v-model="manageCalendarEventDialog" max-width="900px" persistent v-if="activeCalendarEvent">
    <v-card flat class="pb-4">
      <v-toolbar
          :color="activeCalendarEvent.color"
          dark
      >
        <app-btn fab small @click="isReadOnly=!isReadOnly" class="mr-2">
          <v-icon>mdi-{{ isReadOnly ? "pencil" : "binoculars" }}</v-icon>
        </app-btn>
        <v-toolbar-title>{{ !activeCalendarEvent._id ? 'Create Event' : activeCalendarEvent.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <app-btn small @click="deleteBooking" v-if="activeCalendarEvent._id">
          <v-icon>mdi-delete-forever</v-icon>
        </app-btn>
      </v-toolbar>
      <v-form
          ref="modalValid"
          v-model="modalValid"
          lazy-validation @focus="this.$refs.modalValid.validate()"
      >
        <v-subheader v-if="!activeCalendarEvent.calendarId">Select Calendar</v-subheader>
        <div v-if="!activeCalendarEvent.calendarId">
          <v-item-group
              v-model="activeCalendarEvent.calendarId"
          >
            <v-row>
              <v-item v-slot="{ active, toggle }" v-for="g in scopedGroups" :key="g._id" :value="g._id">
                <v-col :cols="12/scopedGroups.length">
                  <group-card :name="g.name" @click="toggle"/>
                </v-col>
              </v-item>
            </v-row>
          </v-item-group>
        </div>
        <v-stepper v-model="activeStepperStep" v-if="activeCalendarEvent.calendarId" non-linear alt-labels>
          <v-stepper-header>
            <v-stepper-step :complete="activeStepperStep > 1" step="1" :rules="[() => step1Valid]">Type<small
                v-if="!step1Valid">Missing category selection</small></v-stepper-step>
            <v-divider/>
            <v-stepper-step :complete="activeStepperStep > 2" step="2" editable :rules="[() => step2Valid]">
              Details<small v-if="!step2Valid">Missing information</small></v-stepper-step>
            <v-divider/>
            <v-stepper-step :complete="activeStepperStep > 3" step="3" editable>Location<small>optional</small>
            </v-stepper-step>
            <v-divider/>
            <v-stepper-step :complete="activeStepperStep > 4" step="4" editable>Description<small>optional</small>
            </v-stepper-step>
            <v-divider/>
            <v-stepper-step :complete="activeStepperStep > 5" step="5" editable>Tags<small>optional</small>
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="activeStepperStep > 6" step="6" editable>Review</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <!--              <v-autocomplete outlined v-model="activeCalendarEvent.category" :items="selectableCategories" item-text="name" item-value="name" :disabled="!activeCalendarEvent.calendarId" dense label="Category" :rules="[() => !!activeCalendarEvent.category || 'This field is required']" />-->
              <v-subheader>Select the resource of category of this event or booking.</v-subheader>
              <v-form
                  ref="step1"
                  v-model="step1Valid"
              >
                <v-text-field
                    outlined
                    dense
                    :value="activeCalendarEvent.category"
                    :rules="[rules.required]"
                    clearable
                    label="Start Date"
                    readonly
                    v-show="false"
                />
                <v-row>
                  <v-col cols="12">
                    <div>
                      <v-chip-group
                          v-model="activeCalendarEvent.category"
                          active-class="secondary--text text--accent-4"
                          column
                      >
                        <v-chip v-for="(c) in selectableCategories" :key="c.name" :value="c.name" filter
                                :color="c.color? c.color['hex']:'primary'" active-class="white--text"
                                size="30" text-color="white">
                          {{ c.name }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col cols="2">

                </v-col>
                <v-spacer/>
                <v-col cols="10">
                  <v-btn block color="primary" @click="activeStepperStep=2" :disabled="!step1Valid">Next</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-form
                  ref="step2"
                  v-model="step2Valid"
              >
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img src="/images/avatars/avatar1.svg"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div class="text-body-1">{{ loggedInPerson.name }}</div>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn text @click="copyToEventName(loggedInPerson.name)">
                      <v-icon>mdi-content-copy</v-icon>
                      Copy name and mobile to Event name
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        outlined
                        dense
                        ref="activeCalendarEvent.name"
                        v-model="activeCalendarEvent.name"
                        :rules="[() => !!activeCalendarEvent.name || 'This field is required']"
                        :error-messages="errorMessages"
                        label="Event Title"
                        placeholder=""
                        required/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-menu
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            outlined
                            dense
                            :value="activeCalendarEvent.startDate"
                            :rules="[rules.required]"
                            clearable
                            label="Start Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="activeCalendarEvent.startDate = null"
                        />
                      </template>
                      <v-date-picker
                          v-model="activeCalendarEvent.startDate"
                          required
                          @input="startDateMenu = false"
                          @change="changeStartDate(activeCalendarEvent.startDate)"
                      ></v-date-picker>
                    </v-menu>
                    <v-btn x-small text outlined @click="toggleEndEventForm">{{ activeCalendarEvent.timed ? '-' : '+' }}
                      End date and time
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                        ref="startTimeMenu"
                        v-model="startTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="activeCalendarEvent.startTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            outlined
                            dense
                            v-model="activeCalendarEvent.startTime"
                            :rules="activeCalendarEvent.timed ? [rules.required]:[]"
                            label="Time"
                            append-icon="mdi-clock"
                            readonly
                            required
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          v-if="startTimeMenu"
                          v-model="activeCalendarEvent.startTime"
                          full-width
                          format="24hr"
                          :allowed-minutes="allowedStep"
                          @click:minute="$refs.startTimeMenu.save(activeCalendarEvent.startTime)"
                          @change="changeStartTime(activeCalendarEvent.startTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <!--                <v-col>-->

                  <!--                  <v-checkbox-->
                  <!--                      :label="`ShowAllDayAll DayTimed Event?`" @click="isAllDay = !isAllDay"/>-->
                  <!--                </v-col>-->
                </v-row>
                <v-row v-show="activeCalendarEvent.timed">
                  <v-col cols="6">
                    <v-menu
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            outlined
                            dense
                            :value="activeCalendarEvent.endDate"
                            :rules="[rules.required]"
                            clearable
                            label="End Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="activeCalendarEvent.endDate = null"/>
                      </template>
                      <v-date-picker
                          :min="activeCalendarEvent.startDate"
                          v-model="activeCalendarEvent.endDate"
                          @input="endDateMenu = false"
                          @change="changeEndDate(activeCalendarEvent.endDate)"
                      ></v-date-picker>
                    </v-menu>
                    {{ computedEndDateFormattedMomentjs }}
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                        ref="endTimeMenu"
                        v-model="endTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="activeCalendarEvent.endTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="activeCalendarEvent.endTime"
                            :rules="[rules.required]"
                            label="Time"
                            append-icon="mdi-clock"
                            outlined
                            readonly
                            required
                            v-bind="attrs"
                            v-on="on"
                            dense
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          v-if="endTimeMenu"
                          v-model="activeCalendarEvent.endTime"
                          full-width
                          format="24hr"
                          :allowed-minutes="allowedStep"
                          :min="activeCalendarEvent.startTime"
                          @click:minute="$refs.endTimeMenu.save(activeCalendarEvent.endTime)"
                          @change="changeEndTime(activeCalendarEvent.endTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row v-show="false">
                  <v-col cols="2">
                    Show As
                  </v-col>
                  <v-col cols="10">
                    <v-select outlined dense v-model="activeCalendarEvent.status" :items="showAsList" label="show as"
                              :rules="[() => !!activeCalendarEvent.status || 'This field is required']">
                      <template v-slot:selection="data">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon :color="data.item.color" v-text="data.item.icon"/>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="data.item.text"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.text"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-icon>
                            <v-icon :color="data.item.color" v-text="data.item.icon"/>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="data.item.text"></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col cols="2">
                  <v-btn block color="primary" @click="activeStepperStep = 1">Back</v-btn>
                </v-col>
                <v-col cols="8">
                  <div class="text-caption text-capitalize">Note</div>
                  <div class="text-body-2">If you wish to add additional information to the event, click on the relevant
                    step above to take you to the section.
                  </div>
                </v-col>
                <v-spacer/>
                <v-col cols="2">
                  <v-btn block color="primary" @click="validate('step2',6)" :disabled="!step2Valid">Review</v-btn>
                </v-col>
              </v-row>

            </v-stepper-content>
            <v-stepper-content step="3">
              <v-row>
                <v-col cols="12">
                  <h1>Location (optional)</h1>
                  <div class="text-body-2">
                    Provide more information about your event so that guests know what to expect.
                  </div>
                  <v-text-field outlined dense v-model="activeCalendarEvent.location"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-btn block color="primary" @click="activeStepperStep = 2">Back</v-btn>
                </v-col>
                <v-spacer/>
                <v-col cols="4">
                  <v-btn block color="primary" @click="activeStepperStep = 6">Review</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="4">
              <h1>Description (optional)</h1>
              <div class="text-body-2">
                Provide more information about your event.
              </div>
              <v-textarea outlined v-model="activeCalendarEvent.eventDetails" full-width no-resize full-height
                          label="Notes"/>
              <v-row>
                <v-col cols="3">
                  <v-btn block color="secondary" @click="activeStepperStep = 2">Back</v-btn>
                </v-col>
                <v-spacer/>
                <v-col cols="4">
                  <v-btn block color="primary" @click="activeStepperStep = 6">Review</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="5">
              <v-subheader>Select one or a number of Tags connected with this event</v-subheader>
              <v-row>
                <v-col cols="12">
                  <div>
                    <v-row>
                      <v-col cols="12">
                        <div>
                          <v-chip-group
                              v-model="activeCalendarEvent.tags"
                              active-class="secondary--text text--accent-4"
                              column
                              multiple
                          >
                            <v-chip v-for="(c) in selectableTags" :key="c.name" :value="c.name" filter
                                    :color="c.color? c.color['hex']:'primary'" active-class="white--text"
                                    size="30" text-color="white">
                              {{ c.name }}
                            </v-chip>
                          </v-chip-group>
                        </div>
                      </v-col>
                    </v-row>
              </div>
              </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-btn block color="secondary" @click="activeStepperStep = 2">Back to Step 2</v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn block color="secondary" @click="activeStepperStep = 3">Back to Step 3</v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn block color="primary" @click="activeStepperStep = 4">Back</v-btn>
                </v-col>
                <v-spacer/>
                <v-col cols="4">
                  <v-btn block color="primary" @click="activeStepperStep = 6">Review</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="6">
              <v-card>
                <v-row>
                  <v-col>
                    <v-list-item-icon>
                      <v-icon x-large color="primary">mdi-calendar-month</v-icon>
                    </v-list-item-icon>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="text-h6 font-weight-bold">{{ activeCalendarEvent.startDate | moment('DD MMM YYYY') }} at
                      {{ activeCalendarEvent.startTime | moment('HH:mm') }}
                      {{ activeCalendarEvent.startTime == activeCalendarEvent.endTime ? '' : '-' }}
                      {{ activeCalendarEvent.startTime == activeCalendarEvent.endTime ? '' : activeCalendarEvent.endTime | moment('HH:mm') }}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="text-h6 font-weight-bold">Category:</div>
                  </v-col>
                  <v-col>
                    <div class="text-h6 font-weight-bold">{{ activeCalendarEvent.category }}</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="text-h6 font-weight-bold">Title:</div>
                  </v-col>
                  <v-col>
                    <div>{{ activeCalendarEvent.name }}</div>
                  </v-col>
                </v-row>
                <v-card>
                  <div class="text-h6 font-weight-bold">Details:</div>
                  <v-row v-if="activeCalendarEvent.ownerName">
                    <v-col>
                      <div class="text-h6 font-weight-bold">Owner:</div>
                    </v-col>
                    <v-col>{{ activeCalendarEvent.ownerName }}</v-col>
                  </v-row>
                </v-card>
              </v-card>
              <v-row>
                <v-col cols="2">
                  <v-btn block color="secondary" @click="activeStepperStep = 2">Back</v-btn>
                </v-col>
                <v-spacer/>
                <v-col cols="10">
                  <v-btn block color="primary" @click="saveBooking" :disabled="!step1Valid && !step2Valid">
                    {{ activeCalendarEvent._id ? 'Save' : 'Create' }} Event
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-form>

      <v-card-actions>
        <v-layout row wrap class="justify-end">
          <v-spacer/>
          <v-flex class="px-2">
            <v-btn text
                   class="secondary mx-0 mt-3"
                   @click="closeDialog"
                   :loading="loading"
                   :disabled="loading">Close
            </v-btn>
          </v-flex>
          <!--        <v-flex class="px-2">-->
          <!--          <v-btn text-->
          <!--                 class="primary mx-0 mt-3"-->
          <!--                 @click="saveBooking" :disabled="!minimumFormDetailsComplete">-->
          <!--            Save-->
          <!--          </v-btn>-->
          <!--        </v-flex>-->
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import {mapState, mapActions} from "vuex";
import {CalendarHttp} from "@/http/CalendarHttp";
import AppBtn from "@/components/app/Btn";
import GroupCard from "@/components/shared/GroupCard";

export default {
  name: "CalendarEventModal",
  components: {GroupCard, AppBtn},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    scopedGroups: {
      type: Array,
      required: true
    },
    calendarRange: {},
    showAsList: {
      type: Array,
      default() {
        return [{
          text: "Tentative Booking or Event",
          value: "tentative",
          color: "secondary",
          icon: "mdi-calendar-question"
        },
          {text: "Confirmed Booking or Event", value: "confirmed", color: "primary", icon: "mdi-calendar-alert"},]
      }
    }
  },
  computed: {
    ...mapState("calendar", ["manageCalendarEventDialog", "activeCalendarEvent"]),
    ...mapState("group", ["groups"]),
    ...mapState("person", ["loggedInPerson"]),

    isExistingEvent() {
      if (!this.activeCalendarEvent) return false
      if (this.activeCalendarEvent._id) return true
      return false
    },
    minimumFormDetailsComplete() {
      return this.activeCalendarEvent.name && this.activeCalendarEvent.startDate && this.activeCalendarEvent.endDate && this.activeCalendarEvent.startTime && this.activeCalendarEvent.endTime && this.activeCalendarEvent.calendarId && this.activeCalendarEvent.category
    },
    computedStartDateFormattedMomentjs() {
      return this.activeCalendarEvent.startDate ? moment(this.activeCalendarEvent.startDate).format('ddd DD/MM/yyyy') : ''
    },
    computedEndDateFormattedMomentjs() {
      return this.activeCalendarEvent.endDate ? moment(this.activeCalendarEvent.endDate).format('ddd DD/MM/yyyy') : ''
    },
    timeSelectItems() {
      const items = []
      for (let h = 0; h < 24; h++) {
        for (let m = 0; m < 60; m = m + 5) {
          items.push(`${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`)
        }
      }
      return items
    },
    selectedGroupData() {
      return this.activeCalendarEvent.calendarId ? this.groups.find(group => group._id === this.activeCalendarEvent.calendarId) : []
    },
    selectableCategories() {
      return this.activeCalendarEvent.calendarId ? this.selectedGroupData.calendarEventCategories.filter(c => c.selectableByGroupMembers) : []
    },
    selectableTags() {
      return this.activeCalendarEvent.calendarId ? this.selectedGroupData.eventTags.filter(c => c.selectableByGroupMembers) : []
    },
    start() {
      return `${this.activeCalendarEvent.startDate} ${this.activeCalendarEvent.startTime}`
    },
    end() {
      return this.activeCalendarEvent.timed ? `${this.activeCalendarEvent.endDate} ${this.activeCalendarEvent.endTime}` : `${this.activeCalendarEvent.startDate} ${this.activeCalendarEvent.startTime}`
    },
    timeEntryDisabled() {
      if (!this.activeCalendarEvent) return false
      return !this.activeCalendarEvent.timed
    },
  },
  data() {
    return {
      //Dialog Box
      isReadOnly: false,
      modalValid: true,
      errorMessages: [],
      startDateMenu: false,
      startTimeOldVar: '00:00',
      startTimeMenu: false,
      endDateMenu: false,
      endTimeOldVar: '00:00',
      endTimeMenu: false,
      startEndDateDiff: 0,
      startEndTimeDiff: 60,
      timeMinInterval: 5,
      useTimeZones: false,
      calendar: null,
      activeStepperStep: 1,
      isAllDay: false,

      rules: {
        required: value => !!value || 'Required.',
      },

      step1Valid: false,
      step2Valid: false,
      step3Valid: false,
      step4Valid: false,
      step5Valid: false,


    }
  },
  methods: {
    ...mapActions("calendar", ["setManageCalendarEventDialog", "setActiveCalendarEvent", "setCalendars"]),
    ...mapActions("app", ["setToast"]),
    copyToEventName(name) {
      let chunkName = name.split(" ")
      this.activeCalendarEvent.name = `${chunkName[1]}, ${chunkName[0]} ${this.loggedInPerson.mobile}`
      this.$forceUpdate();
    },
    // validate () {
    //   this.$refs.modalValid.validate()
    // },
    reset() {
      this.$refs.modalValid.reset()
    },
    async saveBooking() {

      if (this.$refs.modalValid.validate()) {
        this.activeCalendarEvent.start = this.start
        this.activeCalendarEvent.end = this.end
        if (!this.activeCalendarEvent._id) {
          await CalendarHttp.createEvent(this.activeCalendarEvent)
          this.setToast({color: 'success', text: 'Saved', show: true})
        }
        if (this.activeCalendarEvent._id) {
          await CalendarHttp.updateEvent(this.activeCalendarEvent)
          this.setToast({color: 'success', text: 'Updated', show: true})
        }
        this.closeDialog()
      }
    },
    async deleteBooking() {
      await CalendarHttp.deleteEvent(this.activeCalendarEvent._id)
      this.closeDialog()
      this.setToast({color: 'success', text: 'Deleted', show: true})
    },
    async closeDialog() {
      this.$emit("event-changed");
      this.activeStepperStep = 1
      await this.setCalendars(this.calendarRange)
      await this.setActiveCalendarEvent(null)
      await this.setManageCalendarEventDialog(false);
    },
    //dialog
    allowedStep: m => m % 5 === 0,
    toggleTimedEvent(val) {
      if (!val) {
        // store old values
        this.startTimeOldVar = this.activeCalendarEvent.startTime
        this.endTimeOldVar = this.activeCalendarEvent.endTime
        this.activeCalendarEvent.startTime = "00:00"
        this.activeCalendarEvent.endTime = "00:00"
      } else {
        this.activeCalendarEvent.startTime = this.startTimeOldVar
        this.activeCalendarEvent.endTime = this.endTimeOldVar
      }
    },
    changeStartDate(val) {
      if (!val) return
      if (!this.activeCalendarEvent.endDate) {
        return this.activeCalendarEvent.endDate = val
      }
      if (moment(this.activeCalendarEvent.startDate).isAfter(moment(this.activeCalendarEvent.endDate, 'day'))) {
        this.activeCalendarEvent.endDate = val
      }
      this.activeCalendarEvent.endDate = moment(val).add(this.startEndDateDiff, 'days').format("YYYY-MM-DD")

    },
    changeEndDate(val) {
      if (!val) return
      if (!this.activeCalendarEvent.startDate) return this.activeCalendarEvent.startDate = val
      this.setStartEndDiff(this.activeCalendarEvent.startDate, this.activeCalendarEvent.endDate, 'date')
    },
    changeStartTime(val) {
      if (!val) return
      if (!this.activeCalendarEvent.endTime) return this.activeCalendarEvent.endTime = val
    },
    changeEndTime(val) {
      if (!val) return
      if (!this.activeCalendarEvent.startTime) return this.activeCalendarEvent.startTime = val
    },
    setStartEndDiff(start, end, unit) {
      const duration = moment.duration(moment(end).diff(start));
      console.log(duration.asMinutes())
      switch (unit) {
        case "date":
          // code block
          this.startEndDateDiff = duration.asDays();
          break;
        case "time":
          // code block
          this.startEndTimeDiff = duration.asMinutes();
          break;

      }
    },

    validate(form, nextStep) {
      switch (form) {
        case "step1":
          if (this.$refs.step1.validate()) {
            this.step1Valid = true
            this.activeStepperStep = nextStep
          }
          this.step1Valid = false
          break;
        case "step2":
          this.$refs.step2.validate()
          if (this.step2Valid) {
            this.activeStepperStep = nextStep
          }
          break;
        case "step3":
          this.$refs.step3.validate()
          break;
        case "step4":
          this.$refs.step4.validate()
          break;
        case "step5":
          alert('Hey');
          break;
      }
    },

    toggleEndEventForm() {
      this.activeCalendarEvent.timed = !this.activeCalendarEvent.timed
      if (!this.timed) {
        this.activeCalendarEvent.endDate = this.activeCalendarEvent.startDate
        this.activeCalendarEvent.endTime = this.activeCalendarEvent.startTime
      }
    }
  }
}
</script>

<style scoped>

</style>